import { Component, Input, OnInit } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-details-ask-products',
  templateUrl: './details-ask-products.component.html',
  styleUrls: ['./details-ask-products.component.scss']
})
export class DetailsAskProductsComponent implements OnInit{
  @Input() relatedProduct: SectionsData | undefined;

  constructor(private router: Router) {  }

  navigateToNewPath(link: string) {
    this.router.navigate([link])
      .then(() => {
        window.location.reload();
      });
  }

  ngOnInit(): void {
  }
}
