<nav>
  <div class="desktop">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" *ngFor="let item of data; index as i;let last = last" [ngClass]="{ last: last }"
        matTooltip="{{ item.path }}" matTooltipPosition="below" #tooltip="matTooltip">
        <a (click)="navigateToNewPath(item?.routerLink)">{{  i>=1?(item.path | slice : 0 : 50):item.path
          }}<span *ngIf="item.path.length > 8&&i>=1">...</span></a>
      </li>
    </ol>
  </div>
</nav>
<nav>
  <div class="responsive-mobi">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" *ngFor="let item of data; index as i; let last = last"
        [ngClass]="{ last: last }" matTooltip="{{ item.path }}" matTooltipPosition="below" #tooltip="matTooltip">
        <a (click)="navigateToNewPath(item?.routerLink)">{{ i>=1?(item.path | slice : 0 : 8):item.path
          }}<span *ngIf="item.path.length > 8&&i>=1">...</span></a>
      </li>
    </ol>
  </div>
</nav>