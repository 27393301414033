<div class="testimonials">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-12 left-side">
                <div class="box-holder">
                    <img src="../../../../assets/images/testimonials/quote_sign.svg" alt="Quote Sign">
                    <h3 class="main-title">what they say about Qeema</h3>
                </div>
            </div>
            <div class="col-lg-8 col-12 right-side">
                <ngx-slick-carousel class="testimonials-carousel" #slickModal="slick-carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of data" class="slide">
                        <div>
                            <img src="{{ slide.logoUrl }}" alt="{{slide?.clientName}}" title="{{slide?.clientName}}">
                            <!-- <span class="client-name">{{slide?.clientName}}</span> -->
                            <p class="testmonial-content">{{slide?.testimonial}}</p>
                            <div class="author-holder">
                                <span class="author-name">{{slide?.author}}</span>
                                <span class="author-position">{{slide?.position}}</span>
                            </div>
                            <div class="numbers-holder">
                                <div class="row">
                                    <div class="col-md-6 col-12 numbers-box">
                                        <span class="number">{{slide?.numbers[0]?.number}}</span>
                                        <span class="number-sign">%</span>
                                        <span class="number-label">{{slide?.numbers[0]?.represent}}</span>
                                    </div>
                                    <div class="col-md-6 col-12 numbers-box">
                                        <span class="number">{{(slide?.numbers[1]?.number>=1000)
                                            ?
                                            (slide?.numbers[1]?.number/1000)
                                            :
                                            (slide?.numbers[1]?.number)}}</span>

                                        <ng-container *ngIf="(slide?.numbers[1]?.number>=1000)">
                                            <span class="number-sign">K</span>
                                        </ng-container>
                                        <span class="number-sign-puls">+</span>
                                        <span class="number-label">{{slide?.numbers[1]?.represent}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</div>