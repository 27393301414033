import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-card-products',
  templateUrl: './common-card-products.component.html',
  styleUrls: ['./common-card-products.component.scss'],
})
export class CommonCardProductsComponent {
  @Input() subTitle: string | undefined = '';
  @Input() title: string | undefined = '';
  @Input() brief: string | undefined = '';
  @Input() imgUrl: string | undefined = '';
  @Input() link: string | undefined = '';
  outY: any;
  inY: any;
  outX: any;
  inX: any;
  constructor(private router: Router) {}
  onMouseDown(event: any) {
    this.inX = event.clientX;
    this.inY = event.clientY;
  }
  onMouseUp(event: any) {
    this.outX = event.clientX;
    this.outY = event.clientY;
    if (this.inX == this.outX && this.inY == this.outY) {
    }
  }
  navigateToNewPath() {
    this.router.navigate([this.link]);
  }
}
