import { Component, Input } from '@angular/core';
import { SectionsData } from 'src/app/shared/viewModels/sections-data';

@Component({
  selector: 'app-details-video',
  templateUrl: './details-video.component.html',
  styleUrls: ['./details-video.component.scss']
})
export class DetailsVideoComponent {
  @Input() videoSection: SectionsData | undefined;
}
