import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  // standalone:true,
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
  @Input() tabs: any[] = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  @Input() activeTabIndex: any;
  visibale: boolean = false;
  ngOnInit(): void {
  }

  selectedIndex: number = 1;

  ngOnChanges() {
    if (this.tabs) {
      setTimeout(() => (this.visibale = true), 500);
      setTimeout(() => (this.selectedIndex = this.activeTabIndex - 1), 100);
    }
  }
  demo1BtnClick() {
    const tabCount = this.tabs.length;
    this.selectedIndex = (this.selectedIndex + 1) % tabCount;
  }
  demo2BtnClick() {
    if (this.selectedIndex < 1) {
      this.selectedIndex = this.tabsCount;
    } else {
      const tabCount = this.tabs.length;
      this.selectedIndex = (this.selectedIndex - 1) % tabCount;
    }
  }
  private tabsCount = this.tabs.length - 1;

  selectChange(): void {
  }

  SWIPE_ACTION = { LEFT: 'panleft', RIGHT: 'panright' };

  // Action triggered when user swipes
  swipe(selectedIndex: number, event: any) {
    const action = event.type;
    // Out of range
    if (this.selectedIndex < 0) {
      this.selectedIndex = 0;
      return;
    }

    if (this.selectedIndex > this.tabsCount) {
      this.selectedIndex = this.tabsCount;
      return;
    }

    if (event.isFinal) {
      // Swipe left, next tab
      if (action === this.SWIPE_ACTION.LEFT) {
        if (
          (event.pointerType === 'mouse' &&
            ((event.angle <= 180 && event.angle >= 145) ||
              (event.angle <= -145 && event.angle >= -180))) ||
          (event.pointerType === 'touch' &&
            event.angle >= -150 &&
            event.angle <= -130)
        ) {
          const isLast = this.selectedIndex === this.tabsCount;
          const tabCount = this.tabs.length;

          this.selectedIndex = isLast
            ? this.selectedIndex
            : (this.selectedIndex = (this.selectedIndex + 1) % tabCount);

         
        }
      }

      // Swipe right, previous tab
      if (action === this.SWIPE_ACTION.RIGHT) {
        if (
          (event.pointerType === 'mouse' &&
            event.angle >= -45 &&
            event.angle <= 45) ||
          (event.pointerType === 'touch' &&
            event.angle >= -140 &&
            event.angle <= -100)
        ) {
          if (this.selectedIndex < 1) {
            this.selectedIndex = this.tabsCount;
          } else {
            const tabCount = this.tabs.length;
            this.selectedIndex = (this.selectedIndex - 1) % tabCount;
          }

          
        }
      }
    }
  }
}
