<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [title]="title" [brief]="brief" [image]="mainBannerImgUrl" [parentData]="breadCrumb" />
<div class="home-top-about">
  <ng-container *ngIf="aboutSection">
    <app-details-about [aboutSection]="aboutSection" />
  </ng-container>
</div>
<div class="home-top-image-diagram" *ngIf="imageDiagramSection != null">
  <ng-container *ngIf="imageDiagramSection">
    <app-details-image-diagram [imageDiagramSection]="imageDiagramSection" />
  </ng-container>
</div>
<div class="home-top-core" *ngIf="coreFeaturesSection != null">
  <ng-container *ngIf="coreFeaturesSection">
    <app-details-core-features type="service" [coreFeaturesSection]="coreFeaturesSection" />
  </ng-container>
</div>
<div class="home-top-inNumber" *ngIf="inNumbersSection != null">
  <ng-container *ngIf="inNumbersSection">
    <app-details-in-numbers [inNumbersSection]="inNumbersSection" />
  </ng-container>
</div>
<div class="home-top-card" *ngIf="commonCardsSection != null">
  <ng-container *ngIf="commonCardsSection">
    <app-details-common-cards [commonCardsSection]="commonCardsSection" />
  </ng-container>
</div>
<div class="home-top-technology" *ngIf="technologyStackSection != null">
  <ng-container *ngIf="technologyStackSection">
    <app-details-technology-stack [technologyStackSection]="technologyStackSection" />
  </ng-container>
</div>
<div class="top-askServices" *ngIf="isShow != null">
  <app-details-ask-for [title]="askForSection.title" [description]="askForSection.description"
    [link]="askForSection.link" [imageURL]="askForSection.imageURL" />
</div>

<div class="home-top-related" *ngIf="related?.length">
  <ng-container>
    <app-details-related-component type="service" [related]="related" />
  </ng-container>
</div>
<div class="home-top-askProducts" *ngIf="relatedProduct != null">
  <ng-container *ngIf="relatedProduct">
    <app-details-ask-products type="service" [relatedProduct]="relatedProduct" />
  </ng-container>
</div>