<div class="home-section">
  <div class="desktop">
    <div class="d-flex justify-content-center align-items-center">
      <div class="row g-3">
        <div class="col-sm-6 col-md-6 col-lg-4 colsm">
          <div class="box-one boxes text-center">
            <p class="box-title">Proven Track Record</p>
            <p class="box-des">
              With a portfolio of successful projects and satisfied clients, our
              track record speaks volumes about our capabilities and commitment
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 colsm">
          <div class="box-two boxes text-center">
            <p class="box-title">End-to-End Solutions</p>
            <p class="box-des">
              From conceptualization to implementation, we offer a complete
              spectrum of services, ensuring a seamless experience at every
              stage
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 colsm">
          <div class="box-one boxes text-center">
            <p class="box-title">Innovation-driven</p>
            <p class="box-des">
              We don't just follow trends; we set them. Our innovative solutions
              position you at the forefront of your industry
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 colsm">
          <div class="box-two boxes text-center">
            <p class="box-title">Global Reach, Local Expertise</p>
            <p class="box-des">
              ith a presence in Egypt, the Kingdom of Saudi Arabia and Oman, we
              blend international insights with local understanding to deliver
              solutions that resonate
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 colsm">
          <div class="box-one boxes text-center">
            <p class="box-title">Client-Centric Approach</p>
            <p class="box-des">
              Your success is our driving force. We take the time to understand
              your challenges and aspirations, tailoring solutions that address
              your unique needs
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-4 colsm colsm">
          <div class="box-two boxes text-center">
            <p class="box-title">Partnership Philosophy</p>
            <p class="box-des">
              We don't just offer services; we forge lasting partnerships. Your
              success is our success, and we remain committed to your growth and
              transformation
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<style></style>

<div class="responsive">
  <div class="d-flex justify-content-center align-items-center">
    <div class="row g-3">
      <div class="col-sm-6 col-md-6 col-lg-4 colsm">
        <div class="box-one boxes text-center">
          <p class="box-title">Proven Track Record</p>
          <p class="box-des">
            With a portfolio of successful projects and satisfied clients, our
            track record speaks volumes about our capabilities and commitment
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 colsm">
        <div class="box-two boxes text-center">
          <p class="box-title">End-to-End Solutions</p>
          <p class="box-des">
            From conceptualization to implementation, we offer a complete
            spectrum of services, ensuring a seamless experience at every stage
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 colsm">
        <div class="box-two boxes text-center">
          <p class="box-title">Innovation-driven</p>
          <p class="box-des">
            We don't just follow trends; we set them. Our innovative solutions
            position you at the forefront of your industry
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 colsm">
        <div class="box-one boxes text-center">
          <p class="box-title">Global Reach, Local Expertise</p>
          <p class="box-des">
            ith a presence in Egypt, the Kingdom of Saudi Arabia and Oman, we
            blend international insights with local understanding to deliver
            solutions that resonate
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 colsm">
        <div class="box-one boxes text-center">
          <p class="box-title">Client-Centric Approach</p>
          <p class="box-des">
            Your success is our driving force. We take the time to understand
            your challenges and aspirations, tailoring solutions that address
            your unique needs
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 colsm colsm">
        <div class="box-two boxes text-center box">
          <p class="box-title">Partnership Philosophy</p>
          <p class="box-des">
            We don't just offer services; we forge lasting partnerships. Your
            success is our success, and we remain committed to your growth and
            transformation
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
