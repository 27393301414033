import { Component, EventEmitter, Output } from '@angular/core';
import { GetDataService, QeemaNumbers } from '../../services/get-data.service';
import { environment } from 'src/environments/environment';

// export interface qeemaNumbers {
//   headCount:number;
//   growthRevenuePercintage: number;

// }

@Component({
  selector: 'app-statics',
  templateUrl: './statics.component.html',
  styleUrls: ['./statics.component.scss'],
})
export class StaticsComponent {

  @Output() dataEmitter: EventEmitter<any> = new EventEmitter<any>();

  data?: QeemaNumbers;
  constructor(private getData: GetDataService) { }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.getData
      .getQeemaNumbers(`${environment.apiBaseUrl}/api/v1/qeemaNumbers`)
      .subscribe(
        (data) => {
          this.data = data;
          this.dataEmitter.emit(true);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
