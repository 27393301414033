<header>
  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of data" class="slide">
      <div>
        <div class="content-holder">
          <div class="container">
            <div class="content-wrapper">
              <p class="slide-title">{{ slide?.caption }}</p>
              <button class="about-btn" *ngIf="slide?.cta" (click)="navigateToNewPath(slide?.actionPath)">
                {{ slide?.cta }}
              </button>
            </div>
          </div>
        </div>
        <img src="{{ slide?.imgUrl }}" title={{slide?.caption}} alt={{slide?.caption}} />
      </div>
      <div class="content-holder-responsive">
        <p class="slide-title">{{ slide?.caption }}</p>
        <button class="about-btn" *ngIf="slide?.cta" (click)="navigateToNewPath(slide?.actionPath)">
          {{ slide?.cta }}
        </button>
      </div>
    </div>
  </ngx-slick-carousel>
</header>