import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  onChildData(data: Boolean) {
    if (data) {
      setTimeout(() => {
        this.loadingComponent.hideLoading();
        const body = this.el.nativeElement.ownerDocument.body;
        this.renderer.removeClass(body, 'loading-overflow');
      }, 500);
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    //Send page title to google analytics
    gtag('config', 'G-YGS8HSR5WZ', {
      'page_title': "Home",
      'page_path': window.location.pathname
    });
  }
}
