<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<div class="container">
  <div class="newsroom">
    <img class="overlay" src="../../../../assets/images/newsroom/Frame.png" alt="" />
    <div class="cards">
      <div class="home-section">
        <div class="section-head">
          <h2 class="section-title">newsroom</h2>
          <h2 class="section-brief">
            Be the first to know about our innovations, software solutions, products releases, partnerships and more
            that will revolutionize your business.
          </h2>
        </div>
        <div class="container">
          <div class="row gz-6 justify-content-center">
            <div class="news-col d-flex col col-sm-12 col-md-6 col-lg-6 col-xl-4 col-12 justify-content-center"
              *ngFor="let x of dataNews">
              <div class="news-cards">
                <app-news-card [image]="x.imageUrl" [description]="x.introduction" [title]="x.title" [id]="x.id"
                  [link]="'news/' + x?.urlName"></app-news-card>
              </div>
            </div>
          </div>
        </div>
        <div class="content-holder">
          <div class="btn-holder">
            <button class="btn" routerLink="/news">View More News</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>