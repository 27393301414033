<div class="details-technology-stack details-section">
  <div class="container px-4">
    <h2 class="main-title">Technology Stack & Tools</h2>
    <p class="brief">{{ technologyStackSection?.description }}</p>
    <ngx-slick-carousel class="technology-stack-carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let card of technologyStackSection?.cardsData" class="slide">
        <img src="{{ card?.imageUrl }}" alt="{{ card?.imageCaption }}" title="{{ card?.imageCaption }}" />
      </div>
    </ngx-slick-carousel>
  </div>
</div>