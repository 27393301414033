<div class="details-core-features details-section" [ngClass]="type == 'service' ? '' : 'product'">
  <div class="container px-4">
    <h2 class="main-title">{{ coreFeaturesSection?.title }}</h2>
    <p class="brief">{{ coreFeaturesSection?.description }}</p>
    <div class="features-holder">
      <div class="row">
        <div *ngFor="let card of coreFeaturesSection?.cardsData" class="col-xl-4 col-md-6 col-12 mb-3">
          <ng-container *ngIf="type == 'service'; else elseBlock">
            <div class="feature-card-service h-100">
              <div class="left-side">
                <img src="{{ card?.imageUrl }}" alt="{{ card?.title }}" title="{{ card?.title }}" />
              </div>
              <div class="right-side">
                <h3 class="card-title" matTooltip="{{ card?.title }} " matTooltipPosition="below" #tooltip="matTooltip">
                  {{ card?.title }}
                </h3>
                <p class="card-desc" matTooltip="{{ card?.description }} " matTooltipPosition="below"
                  #tooltip="matTooltip">
                  {{ card?.description }}
                </p>
              </div>
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <div class="feature-card-product h-100">
              <img src="{{ card?.imageUrl }}" alt="{{ card?.title }}" title="{{ card?.title }}" />
              <h3 class="card-title" matTooltip="{{ card?.title }} " matTooltipPosition="below" #tooltip="matTooltip">
                {{ card?.title }}
              </h3>
              <p class="card-desc" matTooltip="{{ card?.description }} " matTooltipPosition="below"
                #tooltip="matTooltip">
                {{ card?.description }}
              </p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>