import { Component, ElementRef, HostListener } from '@angular/core';
import { GetDataService } from '../../services/get-data.service';
import { Navbar } from '../../viewModels/navbar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  data: Navbar[] | any = [];
  isNavOpen: boolean = false;
  dropdownHover: boolean = false;

  constructor(
    private getData: GetDataService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    this.listenForClickOutside();
    //Get Nav Data ..
    this.getData.getData(`${environment.apiBaseUrl}/api/v1/navItem`).subscribe(
      (data) => {
        this.data = data.data;
      },
      (error) => {
        console.log(error);
      }
    );
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  listenForClickOutside() {
    document.addEventListener('click', (event) => {
      const targetElement = event.target as HTMLElement;
      if (!this.elementRef.nativeElement.contains(targetElement)) {
        this.closeNavbar();
      }
    });
  }

  // Prevent navigation when clicking inside the dropdown items
  preventNavigation(event: Event) {
    event.stopPropagation(); // Prevent event propagation
  }

  openNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  closeNavbar() {
    this.isNavOpen = false;
  }

  closeDropdownHover() {
    this.dropdownHover = true;
    setTimeout(() => (this.dropdownHover = false), 100);
  }

  @HostListener('document:keydown.escape')
  onKeydownHandler() {
    this.closeNavbar();
  }

  navigateToNewPath(link: string) {
    this.router.navigate([link]).then(() => {
      window.location.reload();
    });
  }
  reload(link: string) {
    this.router.navigate([link]);
  }
}
