<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [title]="title" [brief]="brief" [parentData]="breadCrumb" [image]="image" />
<div class="home-top-about image" *ngIf="aboutSection != null">
  <ng-container *ngIf="aboutSection">
    <app-details-about [aboutSection]="aboutSection" />
  </ng-container>
</div>
<div class="home-top-core" *ngIf="coreFeaturesSection != null">
  <ng-container *ngIf="coreFeaturesSection">
    <app-details-core-features class="pad-title" type="product" [coreFeaturesSection]="coreFeaturesSection" />
  </ng-container>
</div>
<ng-container *ngIf="videoSection">
  <app-details-video [videoSection]="videoSection"></app-details-video>
</ng-container>

<div class="home-top-image-diagram" *ngIf="imageDiagramSection != null">
  <ng-container *ngIf="imageDiagramSection">
    <app-details-image-diagram [imageDiagramSection]="imageDiagramSection" />
  </ng-container>
</div>

<div class="home-top-card" *ngIf="commonCardsSection != null">
  <ng-container *ngIf="commonCardsSection">
    <app-details-common-cards [commonCardsSection]="commonCardsSection" />
  </ng-container>
</div>
<ng-container >
  <app-details-more-images  [reciveMiniAndReverse]="sendMiniAndReserve" />
</ng-container>
<div class="home-top-technology" *ngIf="technologyStackSection != null">
  <ng-container *ngIf="technologyStackSection">
    <app-details-technology-stack [technologyStackSection]="technologyStackSection" />
  </ng-container>
</div>
<div class="home-top-askProducts" *ngIf="isShow != null">
  <app-details-ask-for [title]="askForSection.title" [description]="askForSection.description"
    [link]="askForSection.link" [imageURL]="askForSection.imageURL" />
</div>
<div class="home-top-related" *ngIf="related?.length">
  <ng-container *ngIf="related">
    <app-details-related-component type="product" [related]="related" />
  </ng-container>
</div>