import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { CommonDetails } from './../../shared/viewModels/common-details';
import { SectionsData } from 'src/app/shared/viewModels/sections-data';
import { environment } from 'src/environments/environment';
import { TopCards } from 'src/app/shared/viewModels/top-cards';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';

@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss'],
})
export class ProductsDetailsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  breadCrumb: any = [];
  title: string | undefined = '';
  brief: string | undefined = '';
  image: string | undefined =
    '../../../../assets/images/products-details/stars.svg';
  isShow: boolean = false;
  paramID: string | null = '';
  data: CommonDetails | undefined;
  related: TopCards[] | undefined;
  aboutSection: SectionsData | undefined;
  coreFeaturesSection: SectionsData | undefined;
  videoSection: SectionsData | undefined;
  imageDiagramSection: SectionsData | undefined;
  commonCardsSection: SectionsData | undefined;
  moreImgsSection: SectionsData | undefined;
  miniDefault: SectionsData | undefined;
  sendMiniAndReserve:any[]=[]
  technologyStackSection: SectionsData | undefined;
  askForSection: {
    title: string;
    description: string;
    link: string;
    imageURL: string | undefined;
  };

  constructor(private getData: GetDataService, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) {
    this.paramID = this.route.snapshot.paramMap.get('id');
    this.askForSection = {
      title: 'Request a Live Demo: See Our Solution in Action!',
      description: 'Experience Our Solution Up Close and Personal. ',
      link: '/contact',
      imageURL: '',
    };
  }

  //check Sections Availability
  checkSections() {
    this.breadCrumb = [
      { path: 'Products', routerLink: '/products' },
      {
        path: this.data?.name || 'name',
        routerLink: 'products/details/:id',
      },
    ];
    this.title = this.data?.name;
    this.brief = this.data?.description;
    this.image = this.data?.mainBannerImgUrl;
    this.askForSection.imageURL = this.data?.askBannerImgUrl;

    this.aboutSection = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'default'
    );
    this.coreFeaturesSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'defaultCards'
    );
    this.videoSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'defaultVideo'
    );
    this.imageDiagramSection = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'imageDiagram'
    );
    
    this.moreImgsSection = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'reverseDefault' 
    );
    this.miniDefault = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'miniDefault'
    );
    this.commonCardsSection = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'textCards'
    );
    this.technologyStackSection = this.data?.sectionsData.find(
      (section: SectionsData) => section.type === 'imageCard'
    );
    window.scrollTo(0, 0);
  }

  ngOnInit() {

    this.getData
      .getData(`${environment.apiBaseUrl}/api/v1/products/${this.paramID}`)
      .subscribe(
        (data) => {
          this.data = data?.data[0];
          this.related = data?.related;
          this.checkSections();
          this.hideLoading();
          //Send page title to google analytics
          this.sendPageViewEvent(`Product - ${data?.data[0]?.name}`);
          this.sendMiniAndReserve=data?.data[0]?.sectionsData;
     
        },
        (error) => {
          console.log(error);
          this.checkSections();
        }
      );
  }
 

  private sendPageViewEvent(pageName: string): void {
    gtag('config', 'G-YGS8HSR5WZ', {
      'page_title': pageName,
      'page_path': window.location.pathname
    });
  }

  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
