import { Component, Input } from '@angular/core';
import { SectionsData } from 'src/app/shared/viewModels/sections-data';

@Component({
  selector: 'app-details-in-numbers',
  templateUrl: './details-in-numbers.component.html',
  styleUrls: ['./details-in-numbers.component.scss']
})
export class DetailsInNumbersComponent {
  @Input() inNumbersSection: SectionsData | undefined;
}
