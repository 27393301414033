<app-loading-page #loadingComponent></app-loading-page>
<div class="news-details-page">


<app-inner-banner [image]="image" [parentData]="breadCrumb"></app-inner-banner>
<div class="home-section">
  <div class="container-lg">
    <div class="content-wrap">
      <div class="news-holder">
        <img src="{{ img }}" alt="" />
<!-- <p class="newsDate">{{newsDate | date :'MMM d, y'}}</p> -->
        <h2 class="title">{{ titleNews }}</h2>
        <p class="brief" [innerHTML]="briefNews"></p>
      </div>
    </div>
  </div>
  <div class="news-holder-responsive px-3">
    <div class="content-wrap">
      <img src="{{ img }}" alt="" />
      <!-- <p class="newsDate">{{newsDate | date :'MMM d, y'}}</p> -->
      <h2 class="title">{{ titleNews }}</h2>
      <p class="brief" [innerHTML]="briefNews"></p>
    </div>
  </div>
  <div class="news-holder-responsive-ipad px-5">
    <!-- <p class="newsDate">{{newsDate | date :'MMM d, y'}}</p> -->

    <h2 class="title">{{ titleNews }}</h2>
    <img src="{{ img }}" alt="" />
    <p class="brief" [innerHTML]="briefNews"></p>
  </div>
</div>
</div>
