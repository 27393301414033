import { Component, OnInit } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { TopCards } from 'src/app/shared/viewModels/top-cards';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-services',
  templateUrl: './top-services.component.html',
  styleUrls: ['./top-services.component.scss'],
})
export class TopServicesComponent implements OnInit {
  data: TopCards[] = [];

  constructor(private _data: GetDataService) { }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: true,
    infinite: true,
    arrows: false,
    useTransform: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  ngOnInit(): void {
    this._data
      .getData(`${environment.apiBaseUrl}/api/v1/topServices`)
      .subscribe(
        (data) => {
          this.data = data.data;
        
        },

        (error) => {
          console.log(error);
        }
      );
  }
}
