<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner
  [title]="title"
  [brief]="brief"
  [image]="image"
  [parentData]="breadCrumb"
></app-inner-banner>

<div class="home-section">
  <div class="container">
    <!--<div class="section-head">
      <h2 class="section-title">get in touch with qeema</h2>
      <h2 class="section-brief">
        We are here to connect, collaborate, and create. Reach out to us for
        inquiries, partnerships, or to explore how our solutions can elevate
        your business.
         We are more than happy to serve you by answering your questions through
        <span>info@qeema.net</span> or by submitting the form below.
      </h2>
    </div>-->
    <div class="container">
      <div class="back">
        <div class="row">
          <div class="form col-md-6">
            <div>
              <div class="col" *ngIf="!thanksMessage">
                <div class="form-box">
                  <form
                    #f="ngForm"
                    [formGroup]="contactUsForm"
                    (ngSubmit)="onSubmit(contactUsForm.value)"
                    autocomplete="off"
                  >
                    <div class="margin" style="float: left">
                      <div class="icon-fullName">
                        <input
                          id="fname"
                          placeholder="Full Name ( Mandatory )"
                          type="text"
                          formControlName="fullName"
                          maxlength="100"
                          required
                          [class.is-invalid]="
                            fullName.invalid &&
                            (fullName.dirty || fullName.touched || submitted)
                          "
                        />
                      </div>
                      <div
                        *ngIf="
                          fullName.invalid &&
                          (fullName.dirty || fullName.touched || submitted)
                        "
                        class="text-validation"
                      >
                        <div *ngIf="fullName.hasError('required')">
                          This field is required.
                        </div>
                        <div *ngIf="fullName.hasError('spacesOnly')">
                          This field cannot contain spaces only.
                        </div>
                      </div>
                    </div>
                    <div class="margin">
                      <div class="icon-email">
                        <input
                          id="email"
                          type="email"
                          placeholder="Email ( Mandatory )"
                          formControlName="email"
                          maxlength="100"
                        />
                      </div>
                      <div
                        *ngIf="email.invalid && (email.touched || submitted)"
                      >
                        <div *ngIf="email.hasError('required')">
                          <div class="text-validation">
                            This field is required.
                          </div>
                        </div>
                        <div
                          class="text-validation"
                          *ngIf="email.hasError('email')"
                        >
                          Invalid email format.
                        </div>
                      </div>
                    </div>

                    <div class="phone-mergin">
                      <div class="icon-phone">
                        <ngx-intl-tel-input
                          [cssClass]="'custom'"
                          [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [searchCountryFlag]="true"
                          [customPlaceholder]="'Phone ( Mandatory )'"
                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                          ]"
                          [selectFirstCountry]="true"
                          [selectedCountryISO]="CountryISO.Egypt"
                          [separateDialCode]="separateDialCode"
                          [maxLength]="15"
                          [numberFormat]="PhoneNumberFormat.International"
                          [phoneValidation]="true"
                          name="phone"
                          formControlName="phone"
                          id="phone"
                        >
                        </ngx-intl-tel-input>
                      </div>
                      <div
                        *ngIf="phone.invalid && (phone.touched || submitted)"
                      >
                        <div
                          class="text-validation"
                          *ngIf="!f.form.value.phone"
                        >
                          This field is required.
                        </div>
                        <div
                          class="text-validation"
                          *ngIf="
                            f.form.value.phone &&
                            f.form.controls['phone'].hasError(
                              'validatePhoneNumber'
                            )
                          "
                        >
                          Invalid Phone Number.
                        </div>
                      </div>
                    </div>
                    <div class="margin">
                      <div class="icon-job">
                        <input
                          id="job"
                          type="text"
                          placeholder="Job Title"
                          formControlName="jobTitle"
                          maxlength="100"
                        />
                      </div>
                    </div>
                    <div class="margin">
                      <textarea
                        id="textArea"
                        rows="4"
                        placeholder="Describe Your message ( Mandatory )"
                        formControlName="body"
                        [class.is-invalid]="
                          body.invalid &&
                          (body.dirty || body.touched || submitted)
                        "
                        maxlength="2000"
                      >
                      </textarea>
                      <div
                        *ngIf="
                          body.invalid &&
                          (body.dirty || body.touched || submitted)
                        "
                        class="text-validation-textarea"
                      >
                        <div *ngIf="body.errors?.['required']">
                          This field is required.
                        </div>
                        <div *ngIf="body.hasError('spacesOnly')">
                          This field cannot contain spaces only.
                        </div>
                      </div>
                    </div>
                    <div class="margin-recapthca">
                      <div class="recaptcha">
                        <ngx-recaptcha2
                          #captchaElem
                          siteKey="6LdZ7nAoAAAAAF5LA8dTnV2tnQiUukASMDdqfKxT"
                          formControlName="recaptcha"
                        >
                        </ngx-recaptcha2>
                        <div *ngIf="submitted">
                          <div
                            *ngIf="recaptcha.errors?.['required']"
                            class="text-validation"
                          >
                            This field is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
              <div class="thanksMessage" *ngIf="thanksMessage">
                {{ thanksMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
