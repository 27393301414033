<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [title]="title" [brief]="brief" [parentData]="breadCrumb" [image]="image"></app-inner-banner>

<div class="home-section">
  <div class="section-head">
    <h2 class="section-title">higher ROI Provided by Our Products</h2>
    <h2 class="section-brief">
      We are committed to providing our clients with the best solutions they
      need to achieve their business’s goals.
    </h2>
  </div>
</div>

<div class="common-product-holder">
  <div class="container">
    <div class="row gx-3 gy-5">
      <app-common-card-products class="col-xl-6 col-12" *ngFor="let product of products" [subTitle]="product.name"
        [title]="product.category" [brief]="product.description" [imgUrl]="product?.imageUrl"
        [link]="'products/' + product?.urlName"></app-common-card-products>
    </div>
   
  </div>
</div>
