import { Component, Input } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-common-cards',
  templateUrl: './details-common-cards.component.html',
  styleUrls: ['./details-common-cards.component.scss']
})
export class DetailsCommonCardsComponent {
  @Input() commonCardsSection: SectionsData | undefined;
}
