<div class="home-section">
  <div class="qeema-numbers">
    <div class="ovberlay"></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-12 left-side order-lg-0 order-1">
          <div class="box-holder">
            <div class="top">
              <div class="number-holder">
                <span
                  appCountUpOnScroll
                  [duration]="15"
                  [countUpOnScroll]="data?.qeemaNumbers?.partners || 100"
                  class="number"
                  >0</span
                >
                <span class="sign">+</span>
              </div>
              <h4 class="number-label">Partners</h4>
            </div>
            <hr />
            <div class="bottom">
              <div class="number-holder">
                <span
                  appCountUpOnScroll
                  [duration]="15"
                  [countUpOnScroll]="data?.qeemaNumbers?.countries || 100"
                  class="number"
                  >0</span
                >
                <!-- <span class="sign">%</span> -->
              </div>
              <h4 class="number-label">Countries</h4>
              <button routerLink="/about" class="about-btn d-lg-none d-flex">
                About Us
              </button>
            </div>
          </div>
        </div>
        <div class="col-2 center d-lg-flex d-none">
          <div class="separator-v"></div>
        </div>
        <div class="col-lg-5 col-12 right-side order-lg-1 order-0">
          <div class="box-holder">
            <p class="since">Since 2017</p>
            <h3 class="main-title">qeema growth in numbers</h3>
            <p class="brief">
              Our growth dreams didn’t reach its peak yet , There are success
              stories still written every day.
            </p>
            <button class="about-btn d-lg-flex d-none" routerLink="/about">
              About Us
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
