import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { CommonDetails } from 'src/app/shared/viewModels/common-details';
import { environment } from 'src/environments/environment';
import { SectionsData } from 'src/app/shared/viewModels/sections-data';
import { TopCards } from 'src/app/shared/viewModels/top-cards';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss'],
})
export class ServicesDetailsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  breadCrumb: any = [];
  isShow: boolean = false;
  title: string | undefined = '';
  brief: string | undefined = '';
  mainBannerImgUrl: string | undefined = '';
  paramID: string | null = '';
  data: CommonDetails | undefined;
  related: TopCards[] | undefined;
  aboutSection: SectionsData | undefined;
  imageDiagramSection: SectionsData | undefined;
  coreFeaturesSection: SectionsData | undefined;
  inNumbersSection: SectionsData | undefined;
  commonCardsSection: SectionsData | undefined;
  technologyStackSection: SectionsData | undefined;
  relatedProduct: SectionsData | undefined;
  askForSection: {
    title: string;
    description: string;
    link: string;
    imageURL: string | undefined;
  };

  constructor(private getData: GetDataService, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) {
    this.paramID = this.route.snapshot.paramMap.get('id');
    this.askForSection = {
      title: 'Experience Excellence: Request Our Services Today!',
      description: `Reach out now to discover how we can supercharge your business's growth and potential.`,
      link: '/contact',
      imageURL: '',
    };
  }

  //check Sections Availability
  checkSections() {
    this.breadCrumb = [
      { path: 'Services', routerLink: '/services' },
      {
        path: this.data?.name || 'name',
        routerLink: 'services/details/:id' ,
      },
    ];
    this.title = this.data?.name;
    this.brief = this.data?.description;
    this.mainBannerImgUrl = this.data?.mainBannerImgUrl;

    this.askForSection.imageURL = this.data?.askBannerImgUrl;

    this.aboutSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'default'
    );
    this.imageDiagramSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'imageDiagram'
    );
    this.coreFeaturesSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'defaultCards'
    );
    this.inNumbersSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'numberCards'
    );
    this.commonCardsSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'textCards'
    );
    this.technologyStackSection = this.data?.sectionsData?.find(
      (section: SectionsData) => section.type === 'imageCard'
    );
    this.relatedProduct = this.data?.relatedProduct;
    console.log('relatedProduct', this.relatedProduct);

    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.getData
      .getData(`${environment.apiBaseUrl}/api/v1/services/${this.paramID}`)
      .subscribe(
        (data) => {
          this.data = data?.data[0];
          this.related = data?.related;
          this.checkSections();
          this.hideLoading();
          //Send page title to google analytics
          this.sendPageViewEvent(`Service - ${data?.data[0]?.name}`);
        },
        (error) => {
          console.log(error);
          this.checkSections();
        }
      );
  }

  private sendPageViewEvent(pageName: string): void {
    gtag('config', 'G-YGS8HSR5WZ', {
      'page_title': pageName,
      'page_path': window.location.pathname
    });
  }

  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
