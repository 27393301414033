import { Component, OnInit } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { TopCards } from 'src/app/shared/viewModels/top-cards';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-top-products',
  templateUrl: './top-products.component.html',
  styleUrls: ['./top-products.component.scss']
})
export class TopProductsComponent implements OnInit {

  products: TopCards[] = [];

  constructor(private _data: GetDataService) { }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'dots': true,
    'swipe': true,
    'focusOnSelect': true,
    'infinite': true,
    'draggable': true,
    'mouseWheelMove': true,
    'arrows': false,
    'useTransform': false,
    'responsive':
      [{
        'breakpoint': 1200,
        'settings':
        {
          'slidesToShow': 3,
          'slidesToScroll': 1,
          'draggable': true,
        }
      },

      {
        'breakpoint': 1024,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 768,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 0,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
      ]
  };

  ngOnInit(): void {
    this._data.getData(`${environment.apiBaseUrl}/api/v1/topProducts`).subscribe(
      data => {
        this.products = data.data;
      },
      error => {
        console.log(error);
      }
    );
  }

}
