<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [title]="title" [brief]="brief" [parentData]="breadCrumb" [image]="image" />
<div class="container">
  <div class="home-section">
    <div class="section-head">
      <h2 class="section-title">
        unleash Digital Transformation by our services
      </h2>
      <h2 class="section-brief">
        We combine the power of leading platforms with our intelligence,
        innovation, and industry capabilities to drive large-scale digital
        transformation.
      </h2>
    </div>
  </div>
</div>

<div class="container">
  <div class="tabs-holder">
    <app-tabs [tabs]="data" [activeTabIndex]="tabIndex"></app-tabs>
  </div>
</div>