<div class="home-section">
  <div class="container">
    <div class="section-head">
      <h2 class="section-title">
        explore how our services tackle business challenges
      </h2>
      <h2 class="section-brief">
        We are helping our clients solve bigger and more complex end-to-end
        digital transformation challenges, through our Services.
      </h2>
    </div>

    <ngx-slick-carousel
      class="services-carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div ngxSlickItem *ngFor="let card of data" class="slide h-100">
        <app-common-card
          class="w-100"
          [title]="card.serviceName"
          [image]="card.imgUrl"
          [link]="'services/' + card?.urlName"
        ></app-common-card>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
