<div class="row d-none d-lg-flex" style="margin-bottom: 86px">
  <div class="col-lg-6 col-md-12 col-sm-12">
    <h1 class="title">Who We Are</h1>
    <div class="des-box">
      <p class="decriptaion">
        At Qeema, we redefine possibilities through innovative ICT solutions,
        seamless system integration, and cutting-edge software development. With
        a steadfast commitment to excellence, we have established ourselves as a
        leading name in the industry, delivering transformative solutions that
        transcend boundaries. Our presence extends across Egypt, the Kingdom of
        Saudi Arabia and Oman, where we proudly serve as legal representatives
        for esteemed corporate clients.
      </p>
      <p class="decriptaion">
        Qeema is the embodiment of innovation, collaboration, and expertise in
        the realm of ICT solutions, system integration, and software
        development. Our identity goes beyond being a company; we are architects
        of technological evolution, dedicated to redefining the possibilities of
        the digital landscape.
      </p>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 get-in-touch" style="margin-top: 0%">
    <app-get-in-touch></app-get-in-touch>
  </div>
</div>
<div class="row d-flex d-lg-none flex-column-reverse">
  <div class="col-md-12 col-sm-12" style="margin-top: 65px">
    <h1 class="title">Who We Are</h1>
    <div class="des-box">
      <p class="decriptaion">
        At Qeema, we redefine possibilities through innovative ICT solutions,
        seamless system integration, and cutting-edge software development. With
        a steadfast commitment to excellence, we have established ourselves as a
        leading name in the industry, delivering transformative solutions that
        transcend boundaries. Our presence extends across Egypt, the Kingdom of
        Saudi Arabia and Oman, where we proudly serve as legal representatives
        for esteemed corporate clients.
      </p>
      <p class="decriptaion">
        Qeema is the embodiment of innovation, collaboration, and expertise in
        the realm of ICT solutions, system integration, and software
        development. Our identity goes beyond being a company; we are architects
        of technological evolution, dedicated to redefining the possibilities of
        the digital landscape.
      </p>
    </div>
  </div>
  <div
    class="col-md-12 col-sm-12 get-in-touch justify-content-center align-items-center"
  >
    <app-get-in-touch></app-get-in-touch>
  </div>
</div>
