import { Component } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { Partners } from 'src/app/shared/viewModels/partners';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent {

  constructor(private getData: GetDataService) { }

  data: Partners[] | any = [];
  first: any = [];
  second: any = [];
  customData: any = [];

  ngOnInit() {
    //Get Nav Data ..
    this.getData.getData(`${environment.apiBaseUrl}/api/v1/partner`).subscribe(
      data => {
        this.data = data.data;

        for (let i = 0; i < this.data.length; i++) {
          if (i % 2 === 0) {
            this.first.push({ partnerName1: this.data[i].partnerName, imgUrl1: this.data[i].imgUrl });
          } else {
            this.second.push({ partnerName2: this.data[i].partnerName, imgUrl2: this.data[i].imgUrl });
          }
        }

        for (let index = 0; index < this.first.length; index++) {
          this.customData.push({ ...this.first[index], ...this.second[index] })
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'dots': true,
    'infinite': true,
    'arrows': false,
    'responsive':
      [{
        'breakpoint': 1200,
        'settings':
        {
          'slidesToShow': 3,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 1024,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 600,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 500,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 0,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
      ]
  };
}
