<div class="details-in-numbers details-section">
  <div class="container">
    <div class="header-holder">
      <h2 class="main-title">{{ inNumbersSection?.title }}</h2>
      <p class="brief">{{ inNumbersSection?.description }}</p>
    </div>
    <div class="in-numbers-holder">
      <div class="title-holder">
        <span class="title-p-1">In</span>
        <span class="title-p-2">Numbers</span>
      </div>
      <div class="cards-holder">
        <div class="row">
          <div
            *ngFor="let card of inNumbersSection?.cardsData"
            class="col-xl-4 col-md-6 col-12 mb-5"
          >
            <div class="common-card">
              <div class="content">
                <h3 class="card-title">{{ card?.title }}</h3>
                <p class="card-desc">{{ card?.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
