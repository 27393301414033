import { Component } from '@angular/core';

@Component({
  selector: 'app-qeema-features',
  templateUrl: './qeema-features.component.html',
  styleUrls: ['./qeema-features.component.scss']
})
export class QeemaFeaturesComponent {

}
