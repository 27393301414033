<button
  mat-raised-button
  class="button-right"
  (click)="demo1BtnClick()"
  [ngClass]="visibale ? 'tabs-button' : 'd-none'"
>
  <!-- <i
    class="fa fa-angle-right"
    style="font-size: 22px; color: #828282 !important"
  ></i> -->
  <mat-icon
    style="
      font-size: 25px;
      color: #828282 !important ;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    >chevron_right</mat-icon
  >
</button>
<button
  mat-raised-button
  class="button-left"
  (click)="demo2BtnClick()"
  [ngClass]="visibale ? 'tabs-button' : 'd-none'"
>
  <!-- <i class="fa fa-angle-left" style="font-size: 22px; color: #828282 !important;
  "></i> -->
  <mat-icon
    style="
      font-size: 25px;
      color: #828282 !important ;
      display: flex;
      justify-content: center;
      align-items: baseline;
    "
    >chevron_left</mat-icon
  >
</button>
<div
  (panleft)="swipe(selectedIndex, $event)"
  (panright)="swipe(selectedIndex, $event)"
>
  <mat-tab-group [(selectedIndex)]="selectedIndex">
    <mat-tab
      *ngFor="let tab of tabs"
      [label]="tab?.categoryName"
      (panleft)="swipe(tab?.id, $event)"
      (panright)="swipe(tab?.id, $event)"
    >
      <app-common-tabs [data]="tab?.services"></app-common-tabs>
      <!-- <div style="height:500px;display:flex;justify-content:center;align-items:center;">
      Swipe This Page Left or Right - Content {{tab?.categoryName}}
    </div> -->
    </mat-tab>
  </mat-tab-group>
</div>
