import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent {
  @Input() image: string | undefined;
  @Input() title: string | undefined;
  @Input() description: string | undefined;
  @Input() id: number | undefined = 0;
  @Input() link: string | undefined;
  // newsDate=new Date()

  constructor(private router: Router) { }
  navigateToNewPath() {
    if (this.id) {
      this.router.navigate([this.link])
        .then(() => {
          window.location.reload();
        });
    }
  }

}
