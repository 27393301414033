import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

  @Input() data: any[] = [];

  constructor(private router: Router) { }

  navigateToNewPath(link: string) {
    this.router.navigate([link])
      .then(() => {
        window.location.reload();
      });
  }

}
