<a class="read" (mouseup)="onMouseUp($event)" (mousedown)="onMouseDown($event)">
  <div class="card-holder h-100 w-100 card-hover">
    <div class="img-holder">
      <img src="{{ image }}" title="{{title}}" alt="{{title}}" class="image" />
    </div>
    <h2 class="title">{{ title }}</h2>
    <div class="hover">
      <div class="read">
        <a class="read">Read More</a>
      </div>
      <div class="gg-arrow-right"></div>
    </div>
  </div>
</a>