import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-tabs',
  templateUrl: './common-tabs.component.html',
  styleUrls: ['./common-tabs.component.scss']
})
export class CommonTabsComponent implements OnInit {

  @Input() data: any;
  ngOnInit(): void {
  }
}
