import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { ServicesComponent } from './views/services/services.component';
import { ServicesDetailsComponent } from './views/services-details/services-details.component';
import { ProductsComponent } from './views/products/products.component';
import { ProductsDetailsComponent } from './views/products-details/products-details.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { NewsComponent } from './views/news/news.component';
import { NewsDetailsComponent } from './views/news-details/news-details.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/home',
    pathMatch: 'full',
    data: { title: 'Home' }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home' }
  },
  {
    path: 'services',
    component: ServicesComponent,
    data: { title: 'services' }
  },
  {
    path: 'services/:id',
    component: ServicesDetailsComponent,
    data: { title: 'Services' }
  },

  {
    path: 'products',
    component: ProductsComponent,
    data: { title: 'Products' }
  },
  {
    path: 'products/:id',
    component: ProductsDetailsComponent,
    data: { title: 'Products Details' }
  },
  {
    path: 'contact',
    component: ContactUsComponent,
    data: { title: 'Contact' }
  },
  {
    path: 'about',
    component: AboutUsComponent,
    data: { title: 'About' }
  },
  {
    path: 'news',
    component: NewsComponent,
    data: { title: 'News' }
  },
  {
    path: 'news/:id',
    component: NewsDetailsComponent,
    data: { title: 'News Details' }
  },

  // { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
