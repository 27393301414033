import { Component, Input } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-technology-stack',
  templateUrl: './details-technology-stack.component.html',
  styleUrls: ['./details-technology-stack.component.scss']
})
export class DetailsTechnologyStackComponent {

  @Input() technologyStackSection: SectionsData | undefined;

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'dots': true,
    'infinite': true,
    'arrows': false,
    'responsive':
      [{
        'breakpoint': 1200,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 1024,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 600,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 0,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
      ]
  };

}
