import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-details-ask-for',
  templateUrl: './details-ask-for.component.html',
  styleUrls: ['./details-ask-for.component.scss'],
})
export class DetailsAskForComponent implements OnInit, OnChanges {
  @Input() title: string | undefined = '';
  @Input() description: string | undefined = '';
  @Input() link: string | undefined = '';
  @Input() imageURL: string | undefined = '';

  constructor(private router: Router) {}

  navigateToNewPath() {
    this.router.navigate([this.link]).then(() => {
      window.location.reload();
    });
  }
  ngOnInit(): void {
  }
  ngOnChanges(): void {
  }
}
