<div class="details-more-imgs details-section">
  <div class="container">
    <div class="more-imgs-holder" >
      <div *ngFor="let moreImgsSection of reciveMiniAndReverse ">
      <div class="row image-space"  *ngIf="(moreImgsSection?.type==='miniDefault' )" >

        <div class="left-section col-lg-6 col-12 order-lg-1 order-2">
          <h2 class="main-title">
            {{ moreImgsSection?.title }}
          </h2>
          <p class="description">{{ moreImgsSection?.description }}</p>
        </div>
        <div class="right-section col-lg-6 col-12 order-lg-2  order-1">
          <div class="img-holder">
            <img src="{{ moreImgsSection?.imgUrl }}" [alt]="moreImgsSection?.title" [title]="moreImgsSection?.title" />
          </div>
      </div>
      </div>


   <div class="row image-spaceP-l" *ngIf="(moreImgsSection?.type==='reverseDefault' ) " >
        <div class="left-section col-lg-6 col-12 order-lg-2 order-2">
          <h2 class="main-title">
            {{ moreImgsSection?.title }}
          </h2>
          <p class="description">{{ moreImgsSection?.description }}</p>
        </div>
        <div class="right-section col-lg-6 col-12 order-lg-1 order-1">
          <div class="img-holder">
            <img src="{{ moreImgsSection?.imgUrl }}" [alt]="moreImgsSection?.title"
              [title]="moreImgsSection?.title" />
          </div>
        </div>
  </div>
  </div>
    </div>
 

  </div>