import { Component, Input } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-core-features',
  templateUrl: './details-core-features.component.html',
  styleUrls: ['./details-core-features.component.scss']
})
export class DetailsCoreFeaturesComponent {
  @Input() coreFeaturesSection: SectionsData | undefined;
  @Input() type: string | undefined;
}
