import { Component } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';

@Component({
  selector: 'app-digital-transformation',
  templateUrl: './digital-transformation.component.html',
  styleUrls: ['./digital-transformation.component.scss']
})
export class DigitalTransformationComponent {
  data: any = [];
  constructor(private _data: GetDataService) {

  }


  ngOnInit(): void {
    this._data.getData('https://2zv5y.wiremockapi.cloud/api/v1/topServices').subscribe(
      data => {
        this.data = data.data;
      },
      error => {
        console.log(error);
      }
    );
  }
}


