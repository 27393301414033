import { Component, Input } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-about',
  templateUrl: './details-about.component.html',
  styleUrls: ['./details-about.component.scss']
})
export class DetailsAboutComponent {
  @Input() aboutSection: SectionsData | undefined;
}
