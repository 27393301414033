import { Component, OnInit } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { NewsCard } from 'src/app/shared/viewModels/news-card';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.scss'],
})
export class NewsroomComponent implements OnInit {
  dataNews: any[] = [];

  constructor(private getNewsData: GetDataService) {}

  ngOnInit(): void {
    this.getNewsData
      .getData(`${environment.apiBaseUrl}/api/v1/topNews`)
      .subscribe((data: any) => {
        this.dataNews = data.data;
      
      });
  }
}
