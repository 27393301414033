import { Component } from '@angular/core';
import {
  GetDataService,
  QeemaNumbers,
} from 'src/app/shared/services/get-data.service';
// import { QeemaNumbers } from 'src/app/shared/viewModels/qeema-numbers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qeema-numbers',
  templateUrl: './qeema-numbers.component.html',
  styleUrls: ['./qeema-numbers.component.scss'],
})
export class QeemaNumbersComponent {
  constructor(private getData: GetDataService) {}

  data?: QeemaNumbers;

  ngOnInit() {
    //Get Nav Data ..
    this.getData
      .getQeemaNumbers(`${environment.apiBaseUrl}/api/v1/qeemaNumbers`)
      .subscribe(
        (data) => {
          this.data = data;
          console.log('qeemaNumber', data);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
