<div class="home-section">
  <div class="parners">
    <div class="container">
      <div class="section-head">
        <h2 class="section-title">Partners we trust</h2>
        <h2 class="section-brief">
          Whatever you need, we've got you covered, we’re dealing with the
          largest technologies companies to meet the needs of your business.
        </h2>
      </div>
      <ngx-slick-carousel class="partners-carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of customData" class="slide">
          <div class="imgs-holder">
            <div class="img-box">
              <img src="{{ slide.imgUrl1 }}" alt="{{ slide?.partnerName1 }}" title="{{ slide?.partnerName1 }}" />
            </div>
            <div class="img-box">
              <img src="{{ slide.imgUrl2 }}" alt="{{ slide?.partnerName2 }}" title="{{ slide?.partnerName2 }}" />
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>