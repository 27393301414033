<header>
  <div class="banImg">
    <img src="{{ image }}" alt="Banner Image" />
    <div class="content-holder-inner">
      <div class="container-lg">
        <div class="content-wrap">
          <div><app-breadcrumb [data]="parentData" /></div>
          <h2
            class="title"
            matTooltip="{{ title }}"
            matTooltipPosition="below"
            #tooltip="matTooltip"
          >
            {{ title }}
          </h2>
          <p
            class="brief"
            matTooltip="{{ brief }}"
            matTooltipPosition="below"
            #tooltip="matTooltip"
          >
            {{ brief }}
          </p>
        </div>
      </div>
    </div>
    <div class="content-holder-responsive">
      <div class="container-lg">
        <div class="content-wrap">
          <div class="content-ti-br">
            <div class="banner"><app-breadcrumb [data]="parentData" /></div>
            <h2
              class="title"
              matTooltipPosition="after"
              matTooltip="{{ title }}"
              #tooltip="matTooltip"
            >
              {{ title }}
            </h2>
            <p
              class="brief"
              matTooltipPosition="after"
              matTooltip="{{ brief }}"
              #tooltip="matTooltip"
            >
              {{ brief }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
