import { Component, Input, OnInit } from '@angular/core';
import { SectionsData } from 'src/app/shared/viewModels/sections-data';

@Component({
  selector: 'app-details-more-images',
  templateUrl: './details-more-images.component.html',
  styleUrls: ['./details-more-images.component.scss']
})
export class DetailsMoreImagesComponent {

  @Input() moreImgsSection: SectionsData | undefined;
  @Input() moreReverseSection: SectionsData | undefined;
@Input() reciveMiniAndReverse :any;

}
