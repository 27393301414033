import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-card-top-products',
  templateUrl: './common-card-top-products.component.html',
  styleUrls: ['./common-card-top-products.component.scss'],
})
export class CommonCardTopProductsComponent {
  @Input() subTitle: string | undefined = '';
  @Input() title: string | undefined = '';
  @Input() brief: string | undefined = '';
  @Input() link: string | undefined = '';
  outX: any;
  outY: any;
  inX: any;
  inY: any;

  constructor(private router: Router) { }

  onMouseDown(event: any) {
    this.inX = event.clientX;
    this.inY = event.clientY;
  }
  onMouseUp(event: any) {
    this.outX = event.clientX;
    this.outY = event.clientY;
    if (this.inX == this.outX && this.inY == this.outY) {
      this.navigate();
    }
  }
  navigate() {
    this.router.navigate([this.link]).then(() => {
      window.location.reload();
    });
  }
  cards =[{
title:'Network Inventory Solution Network Inventory Solution'
  },

{
  title:'Network Inventory Solution Network Inventory Solution Network Inventory Solution Network Inventory Solution'
}
]
}
