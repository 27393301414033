<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [image]="image" [title]="title" [brief]="brief" [parentData]="breadCrumb"></app-inner-banner>

<div class="d-flex justify-content-center align-items-center flex-column">
  <div class="container-lg">
    <div class="who-we-are">
      <app-who-we-are></app-who-we-are>
    </div>

    <!-- SECTION TWO -->
    <div class="app-statics">
      <app-statics (dataEmitter)="onChildData($event)"></app-statics>
    </div>
    <div class="p-2 gap text-center d-flex justify-content-center flex-column align-items-center">
      <h2 class="qeema-title">Why Choose Qeema ?</h2>
      <!-- <p class="px-5  qeema-des  ">Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper sagittis
        ornare nam facilisis purus neque donec.</p> -->
      <div class="qeema-features">
        <app-qeema-features></app-qeema-features>
      </div>
    </div>
    <div class="app-success">
      <app-success-drivers></app-success-drivers>
    </div>
  </div>
</div>
<!-- SECTION TWO -->
<!-- 
<div class="top-services">
  <app-top-services></app-top-services>
</div>
<div class="top-products">
  <app-top-products></app-top-products>
</div>
<div class="top-Askservices"></div>
<app-about-us-askfor
  [title]="askForSection?.title"
  [description]="askForSection?.description"
  [link]="askForSection?.link"
></app-about-us-askfor>
<app-customers></app-customers>
<app-partners></app-partners> -->