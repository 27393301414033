<a class="read" (mouseup)="onMouseUp($event)" (mousedown)="onMouseDown($event)" (click)="navigateToNewPath()">
  <div class="common-products  card-hover  h-100 d-flex">
    <div class="row justify-content-between">
      <div class="left-section col-lg-5 col-12 order-lg-1 order-2 card-content">
        <p class="subTitle">{{ subTitle }}</p>
        <h2 class="title d-flex flex-grow-1">{{ brief }}</h2>
        <!-- <p class="brief d-flex flex-grow-1" >{{ brief }}</p> -->
        <div class="hover ">
          <div class="read">
            <a class="read" (click)="navigateToNewPath()">Learn More</a>
          </div>
          <div class="gg-arrow-right"></div>
        </div>
      </div>

      <div class="right-section col-lg-6 col-12 order-lg-2 order-1">
        <ng-container *ngIf="imgUrl">
          <div class="image-holder">
            <img src="{{ imgUrl }}" alt="{{ subTitle }}" title="{{ subTitle }}" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</a>