<nav class="navbar navbar-expand-xl p-0">
  <!-- Start Desktop -->
  <div class="container-xl">
    <a class="navbar-brand p-0" routerLink="/home">
      <img src="../../../../assets/images/navbar/navbar_logo.svg" title="Qeema" alt="Qeema Logo" />
    </a>
    <button class="navbar-toggler" (click)="openNav()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li *ngFor="let item of data" class="nav-item drop-menu" [ngClass]="item?.isCta ? 'contactus-holder' : ''"
          [routerLink]="'/'+item?.routeName">
          <!--            [routerLink]="item?.routeName"-->
          <!-- (click)="reload('/'+item?.routeName)" routerLinkActive="active" -->
          <a class="nav-link" [ngClass]="item?.items ? 'has-items' : ''" routerLinkActive="active">{{ item?.displayName
            }}</a>
          <ng-container *ngIf="item?.items">
            <!-- Start Mega Menu  -->
            <div *ngIf="item?.isMegaMenu; else elseBlock" class="drop-down-desktop"
              [ngClass]="dropdownHover ? 'close-dropdown-hover' : ''">
              <div class="drop-down-body" (click)="preventNavigation($event)">
                <ng-container *ngFor="let col of item?.items; last as isLast">
                  <ul>
                    <li class="col-title">{{ col?.displayName }}</li>
                    <ng-container *ngFor="let colItem of col?.items; index as i">
                      <li *ngIf="i < 5">
                        <a (click)="
                            closeDropdownHover();
                            navigateToNewPath(
                              item?.routeName + '/' + colItem?.urlName
                            )
                          " routerLinkActive="active">
                          {{ colItem?.displayName }}
                        </a>
                      </li>
                    </ng-container>
                    <li *ngIf="col?.items?.length > 5" class="show-all">
                      <a [routerLink]="['/'+item?.routeName, col?.urlName]" routerLinkActive="active"
                        (click)="closeDropdownHover()">Show all<span>&#8594;</span></a>
                    </li>
                  </ul>
                  <div *ngIf="!isLast" class="v-separator"></div>
                </ng-container>
              </div>
            </div>
            <!-- End Mega Menu  -->
            <!-- Start Regular Menu  -->
            <ng-template #elseBlock>
              <div class="drop-down-desktop regular-dropdown" [ngClass]="dropdownHover ? 'close-dropdown-hover' : ''">
                <div class="drop-down-body" (click)="preventNavigation($event)">
                  <ul>
                    <ng-container *ngFor=" let col of item?.items; index as i">
                      <li *ngIf="i < 5">
                        <!-- [routerLink]=" [item?.routeName + '/details',col?.id]" -->
                        <a (click)="
                            closeDropdownHover();
                            navigateToNewPath(
                              item?.routeName + '/' + col?.urlName
                            )
                          " routerLinkActive="active">
                          {{ col?.displayName }}
                        </a>
                      </li>
                    </ng-container>
                    <li *ngIf="item?.items?.length > 5" class="show-all">
                      <a [routerLink]="'/'+item?.routeName" routerLinkActive="active"
                        (click)="closeDropdownHover()">Show
                        all<span>&#8594;</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-template>
            <!-- End Regular Menu  -->
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
  <!-- End Desktop -->
  <!-- Start Mobile -->
  <div class="opened-navbar" [ngClass]="isNavOpen ? 'opened' : ''">
    <div class="top-holder">
      <a class="navbar-brand p-0" (click)="openNav()" routerLink="/home">
        <img src="../../../../assets/images/navbar/navbar_logo_black.svg" title="Qeema" alt="Qeema Logo" />
      </a>
      <div class="close-icon" (click)="openNav()">&times;</div>
    </div>
    <ul class="navbar-nav">
      <li *ngFor="let item of data" class="nav-item" [ngClass]="item?.items ? 'dropdown' : ''"
        (click)="item?.items ? '' : openNav()" [routerLink]="'/'+item?.routeName">
        <a class="nav-link" [ngClass]="[
            item?.items ? 'dropdown-toggle' : '',
            item?.isCta ? 'contactus-holder' : ''
          ]" routerLinkActive="active" id="{{ item?.routeName }}" data-bs-toggle="dropdown"
          (click)="item?.items ? [$event.stopPropagation(),$event.preventDefault()]:''">
          {{ item?.displayName }}
        </a>
        <ng-container *ngIf="item?.items">
          <!-- Start Mega Menu  -->
          <div *ngIf="item?.isMegaMenu; else elseBlock">
            <ul class="dropdown-menu" [attr.aria-labelledby]="item?.routeName" (click)="preventNavigation($event)">
              <li class="main-title-extra" [routerLink]="'/'+item?.routeName" (click)="openNav()">
                Overview
              </li>
              <div class="v-separator"></div>
              <ng-container *ngFor="let col of item?.items; last as isLast">
                <ul>
                  <li class="col-title">{{ col?.displayName }}</li>
                  <ng-container *ngFor="let colItem of col?.items; index as i">
                    <li *ngIf="i < 2" (click)="openNav()" class="item-holder">
                      <a (click)="
                          navigateToNewPath(
                            item?.routeName + '/' + colItem?.urlName
                          )
                        " routerLinkActive="active">
                        {{ colItem?.displayName }}
                      </a>
                    </li>
                  </ng-container>
                  <li *ngIf="col?.items?.length > 2" class="show-all item-holder" (click)="openNav()">
                    <a [routerLink]="['/'+item?.routeName,'index', col?.id]" routerLinkActive="active">Show
                      all<span>&#8594;</span></a>
                  </li>
                </ul>
                <div *ngIf="!isLast" class="v-separator"></div>
              </ng-container>
            </ul>
          </div>
          <!-- End Mega Menu  -->
          <!-- Start Regular Menu  -->
          <ng-template #elseBlock>
            <div class="dropdown-menu" [attr.aria-labelledby]="item?.routeName" (click)="preventNavigation($event)">
      <li class="main-title-extra" [routerLink]="'/'+item?.routeName" (click)="openNav()">
        Overview
      </li>
      <div class="v-separator"></div>
      <ul>
        <ng-container *ngFor="let col of item?.items; index as i">
          <li *ngIf="i < 3" (click)="openNav()">
            <a (click)="
                        navigateToNewPath(
                          item?.routeName + '/' + col?.urlName
                        )
                      " routerLinkActive="active">
              {{ col?.displayName }}
            </a>
          </li>
        </ng-container>
        <li *ngIf="item?.items?.length > 3" class="show-all" (click)="openNav()">
          <a [routerLink]="'/'+item?.routeName" routerLinkActive="active">Show all<span>&#8594;</span></a>
        </li>
      </ul>
  </div>
  </ng-template>
  <!-- End Regular Menu  -->
  </ng-container>
  </li>
  </ul>
  </div>
  <!-- End Mobile -->
</nav>