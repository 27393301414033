import { Component, Input } from '@angular/core';
import { TopCards } from '../../viewModels/top-cards';

@Component({
  selector: 'app-details-related-component',
  templateUrl: './details-related-component.component.html',
  styleUrls: ['./details-related-component.component.scss'],
})
export class DetailsRelatedComponentComponent {
  @Input() related: TopCards[] | undefined;
  @Input() type: string | undefined;
  productTitle: string = 'More Products';
  productBrief: string =
    'We are committed to providing our clients with the best solutions they need to achieve their business’s goals.';
  serviceTitle: string = 'Related Services';
  serviceBrief: string =
    'Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper sagittis ornare nam facilisis purus neque donec. Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper sagittis ornare nam facilisis purus neque donec. Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper.';

  slideConfigServ = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: true,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  slideConfigProd = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: true,
    infinite: true,
    arrows: false,
    responsive: [
      // {
      //   breakpoint: 1400,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //   },
      // },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
