<div class="details-ask-products">
  <div class="container">
    <div class="ask-products-holder" [style.background]="'url(' + relatedProduct?.relatedBannerImgUrl + ')'"
      [style.background-repeat]="'no-repeat'" [style.background]="'contain'" [style.background-size]="'cover'">
      <div class="col-6 d-xl-block d-none">
        <div class="content">
          <h2 class="title">
            <!-- Ask a Demo for {{ relatedProduct?.name }} Products ? -->
            Request a Live Demo: See Our Solutions in Action!
          </h2>
          <p class="desc">
            Meet our powerful product {{ relatedProduct?.name }}<br> Learn more
            about {{ relatedProduct?.name }}
          </p>
          <a class="link" (click)="
              navigateToNewPath('products/details/' + relatedProduct?.id)
            ">Explore More</a>
        </div>
      </div>
    </div>
    <div class="content-responsive d-xl-none d-block">
      <div class="content px-3">
        <h2 class="title">
          <!-- Ask a Demo for {{ relatedProduct?.name }} Products ? -->
          Request a Live Demo: See Our Solutions in Action!
        </h2>
        <p class="desc">
          Meet our powerful product {{ relatedProduct?.name }} Learn more about
          {{ relatedProduct?.name }}
        </p>
        <a class="link" (click)="navigateToNewPath('products/details/' + relatedProduct?.id)">Explore More</a>
      </div>
    </div>
  </div>
</div>