import { Component } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { Testimonials } from 'src/app/shared/viewModels/testimonials';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {

  constructor(private getData: GetDataService) { }

  data: Testimonials[] | any = [];

  ngOnInit() {
    //Get Nav Data ..
    this.getData.getData(`${environment.apiBaseUrl}/api/v1/testimonial`).subscribe(
      data => {
        this.data = data.data;
      },
      error => {
        console.log(error);
      }
    );
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'dots': true,
    'infinite': true,
    'arrows': false,
  };

}
