import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCountUpOnScroll]'
})
export class CountUpOnScrollDirective {

  @Input('countUpOnScroll') targetNumber: number = 0;
  @Input('duration') duration: number = 0;
  private hasCounted = false;

  constructor(private elementRef: ElementRef) { }

  @HostListener('window:scroll')
  onScroll() {
    if (!this.hasCounted && this.isElementInViewport()) {
      this.startCounting();
      this.hasCounted = true;
    }
  }

  private isElementInViewport(): boolean {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.top <= windowHeight && rect.bottom >= 0;
  }

  private startCounting() {
    let counter = 0;
    const interval = setInterval(() => {
      if (counter >= this.targetNumber) {
        clearInterval(interval);
      }
      this.elementRef.nativeElement.textContent = counter.toString();
      counter++;
    }, this.duration);
  }
}