<div class="home-section">
  <div class="container">
    <div class="section-head">
      <h2 class="section-title">
        dealing with Top Niche Customers in the Market
      </h2>
      <h2 class="section-brief">
        We’re proud to work with the leading companies, we will always strive to
        be the partner you can rely on, because we take pride in being part of
        your story.
      </h2>
    </div>
    <ngx-slick-carousel
      class="customers-carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div ngxSlickItem *ngFor="let slide of data" class="slide">
        <img
          src="{{ slide?.imgUrl }}"
          alt="{{ slide?.clientName }}"
          title="{{ slide?.clientName }}"
        />
      </div>
    </ngx-slick-carousel>
  </div>
</div>
