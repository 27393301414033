<div class="details-ask-for">
  <div class="ask-for-holder">
      <div class="container">
          <div class="row">
              <div class="col-6 d-xl-block d-none">
                  <div class="content">
                      <h2 class="title">{{title}}</h2>
                      <p class="desc">{{description}}</p>
                      <a class="link" (click)="navigateToNewPath()">Get In Touch</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="content-responsive d-xl-none d-block">
      <div class="container">
          <div class="content">
              <h2 class="title">{{title}}</h2>
              <p class="desc">{{description}}</p>
              <a class="link" (click)="navigateToNewPath()">Get In Touch</a>
          </div>
      </div>
  </div>
</div>
