import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  image: string = '../../../assets/images/about-us/aboutus_banner.jpg';
  askForSection:
    | { title: string; description: string; link: string }
    | undefined;
  breadCrumb: any = [{ path: 'About us ', routerLink: '/About Us ' }];
  title: string = 'About Us';
  brief: string =
    'Increase the speed of software delivery, reduce the cost of software development and operations, and increase the software quality and security. ';
  constructor(private getData: GetDataService, private renderer: Renderer2, private el: ElementRef) {
    this.askForSection = {
      title: 'Ask a Services ?',
      description:
        'Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper sagittis ornare nam facilisis purus neque donec. Lorem ipsum dolor sit amet consectetur. ',
      link: '/contact',
    };
  }
  data = [];
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.getData.getData(`${environment.apiBaseUrl}/api/v1/qeemaNumbers`).subscribe(
    //   data => {
    //     this.data = data.content;
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );

    //Send page title to google analytics
    gtag('config', 'G-YGS8HSR5WZ', {
      page_title: 'About Us',
      page_path: window.location.pathname,
    });
  }

  onChildData(data: Boolean) {
    if (data) {
      setTimeout(() => {
        this.loadingComponent.hideLoading();
        const body = this.el.nativeElement.ownerDocument.body;
        this.renderer.removeClass(body, 'loading-overflow');
      }, 500);
    }
  }
}
