import { Component, Input } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-image-diagram',
  templateUrl: './details-image-diagram.component.html',
  styleUrls: ['./details-image-diagram.component.scss'],
})
export class DetailsImageDiagramComponent {
  @Input() imageDiagramSection: SectionsData | undefined;
}
