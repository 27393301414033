<app-loading-page #loadingComponent></app-loading-page>

<app-inner-banner [image]="image" [title]="pageTitle" [brief]="brief" [parentData]="breadCrumb"></app-inner-banner>

<div class="main">
  <!-- <h1 class="news-title">Latest News</h1>
  <p class="des">
    Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper sagittis
    <br />
    ornare nam facilisis purus neque donec.
  </p> -->
</div>
<div class="container">
  <div class="content-holder">
    <div class="row gz-6">
      <div class="news-col d-flex col col-sm-12 col-md-6 col-lg-6 col-xl-4 col-12 justify-content-center"
        *ngFor="let x of data | paginate : { itemsPerPage: 9, currentPage: request.first ,  totalItems: totalItems}">
        <div class="news-cards">
          <app-news-card [image]="x.imageUrl" [description]="x.introduction" [title]="x.title" [id]="x.id"
            [link]="'news/' + x?.urlName"></app-news-card>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-center pages" style="min-width: 200px">
          <pagination-controls previousLabel="" nextLabel="" class="my-pagination" style="margin-right: 50px"
          (pageChange)="onTableDataChange(request.first=$event)"  [(ngModel)]='request.first' >
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>