
<div class="common-cards-holder-tabs">
  <div class="row gx-4 gy-5">
    <div class="col-xl-4 col-12 col-md-6"  *ngFor="let emp of data">
      <app-common-card [title]="emp.name" [image]="emp.imgUrl" [link]="'services/' + emp?.urlName"></app-common-card>
    </div>
  </div>
</div>
  



