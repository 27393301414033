<div class="details-common-cards details-section">
  <div class="container px-4">
    <h2 class="main-title">{{ commonCardsSection?.title }}</h2>
    <p class="brief">{{ commonCardsSection?.description }}</p>
    <div class="common-cards-holder">
      <div class="row">
        <div
          *ngFor="let card of commonCardsSection?.cardsData"
          class="col-xl-4 col-md-6 col-12 mb-4"
        >
          <div class="common-card">
            <div class="overlay"></div>
            <div class="content">
              <h3 class="card-title">{{ card?.title }}</h3>
              <p class="card-desc">{{ card?.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
