<div class="video-section details-section">
    <div class="container">
        <div class="video-holder">
            <div class="row">
                <div class="left-side col-lg-6 col-12">
                    <div class="beforeBorder"></div>
                    <iframe [src]="videoSection?.videoUrl| safe" allowfullscreen>
                    </iframe>
                    <div class="afterBorder"></div>
                </div>
                <div class="right-side col-lg-6 col-12">
                    <h3 class="main-title">{{videoSection?.title}}</h3>
                    <p class="description">{{videoSection?.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>