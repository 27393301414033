import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Navbar } from '../viewModels/navbar';
import { environment } from 'src/environments/environment';
export interface QeemaNumbers {
  qeemaNumbers: { partners: number; countries: number };
}
@Injectable({
  providedIn: 'root',
})
export class GetDataService {
  constructor(private http: HttpClient) {}
  private url = `${environment.apiBaseUrl}/api/v1/news`;
  getData(apiUrl: string): Observable<any> {
    return this.http.get<any>(apiUrl);
  }
  getQeemaNumbers(apiUrl: string): Observable<QeemaNumbers> {
    return this.http.get<QeemaNumbers>(apiUrl);
  }

  postData(apiUrl: string, data: any): Observable<any> {
    return this.http.post<any>(apiUrl, data);
  }
  getDataPagination(
    first:number,
 sizePage:number
  ): Observable<any> {



      return this.http.get<any>(this.url, {
        params: new HttpParams().set('page', first).set('size', sizePage),
      });
    }
  
}
