import { Component } from '@angular/core';

@Component({
  selector: 'app-success-drivers',
  templateUrl: './success-drivers.component.html',
  styleUrls: ['./success-drivers.component.scss']
})
export class SuccessDriversComponent {

}
