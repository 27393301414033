<div class="row d-flex justify-content-around align-items-center">
  <div class="col-sm-12 col-md-6 p-2 padd-bottom">
    <div class="item-box">
      <img class="img-fluid" src="assets\images\about-us\partnero.svg" alt="Partners" title="Partners" />
      <div>
        <h1 class="head-counter">
          {{ data?.qeemaNumbers?.partners }} <span class="sign">+</span>
        </h1>
        <p class="head-title">Partners</p>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-4 p-2">
    <div class="item-box">
      <img src="assets\images\about-us\countrieso.svg" alt="Countries" title="Countries" />
      <div>
        <h1 class="head-counter">{{ data?.qeemaNumbers?.countries }}</h1>
        <p class="head-title">Countries</p>
      </div>
    </div>
  </div>
</div>

<style></style>