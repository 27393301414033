<div class="home-section">
  <div class="container">
    <div class="section-head">
      <h2 class="section-title">browse our latest Products</h2>
      <h2 class="section-brief">
        Create memorable customer experiences and skyrocket your business’s
        efficiency.
      </h2>
    </div>
    <ngx-slick-carousel
      class="product-carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
    >
      <div ngxSlickItem *ngFor="let product of products" class="slide">
        <app-common-card-top-products
          class="w-100"
          [subTitle]="product?.name"
          [title]="product?.category"
          [brief]="product?.description"
          [link]="'products/' + product?.urlName"
        ></app-common-card-top-products>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
