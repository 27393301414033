<div class="details-about details-section">
  <div class="container">
    <div class="row">
      <div class="left-section col-lg-6 col-12 order-lg-1 order-2 px-4">
        <h2 class="main-title">{{ aboutSection?.title }}</h2>
        <p class="description">{{ aboutSection?.description }}</p>
      </div>
      <div class="right-section col-lg-6 col-12 order-lg-2 order-1">
        <img class="about-img-holder" src="{{ aboutSection?.imgUrl }}" [alt]="aboutSection?.title"
          [title]="aboutSection?.title" />
        <h6 class="about-img-caption">{{ aboutSection?.imageCaption }}</h6>
      </div>
    </div>
  </div>
</div>