<a (click)="navigateToNewPath()" class="text-decoration-none h-100">
  <div class="out-box h-100">
    <div class="img-holder">
      <img class="" src="{{ image }}" alt="{{ title }}" title="{{ title }}" />
    </div>
    <div class="inner-box">
      <div
        class="d-flex flex-column justify-content-around align-items-start h-100"
      >
        <div>
          <!-- <p class="newsDate">{{newsDate | date :'MMM d, y'}}</p> -->

          <p
            class="title"
            matTooltip="{{ title }} "
            matTooltipPosition="below"
            #tooltip="matTooltip"
          >
            {{ title }}
          </p>
        </div>
        <!-- <div class="desc-holder">
          <p class="desc" matTooltip="{{ description }} " matTooltipPosition="below" #tooltip="matTooltip">
            {{ description }}
          </p>
        </div> -->
        <div class="hover mt-2">
          <div class="read">
            <a class="read" (click)="navigateToNewPath()">Read More</a>
          </div>
          <div class="gg-arrow-right"></div>
        </div>
      </div>
    </div>
  </div>
</a>
