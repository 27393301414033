<a class="read" (mouseup)="onMouseUp($event)" (mousedown)="onMouseDown($event)">
  <div class=" w-100 card-hover d-flex">
    <div class="common-top-products card-content w-100 ">
      <p class="subTitle">{{ subTitle }}</p>
      <!-- <h2 class="title d-flex flex-grow-1">{{ title }}</h2> -->
<div style="height: 100px; margin-bottom: 40px;">
  <p class="title d-flex flex-grow-1" >{{ brief }}</p>

</div>
      <div class="hover ">
        <div class="read">
          <a class="read">Learn More</a>
        </div>
        <div class="gg-arrow-right"></div>
      </div>
    </div>
  </div>
</a>
