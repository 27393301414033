import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us-askfor',
  templateUrl: './about-us-askfor.component.html',
  styleUrls: ['./about-us-askfor.component.scss']
})
export class AboutUsAskforComponent {
  @Input() title: string | undefined = "";
  @Input() description: string | undefined = "";
  @Input() link: string | undefined = "";

  constructor(private router: Router) { }

  navigateToNewPath() {
    this.router.navigate([this.link])
      .then(() => {
        window.location.reload();
      });
  }
}
