<div
  class="details-image-diagram details-section"
  [style.background]="imageDiagramSection?.imageBackgroundColor"
>
  <div class="container">
    <img
      class="imgHolder"
      [src]="imageDiagramSection?.imgUrl"
      [alt]="imageDiagramSection?.imageCaption"
      [title]="imageDiagramSection?.imageCaption"
    />
    <p class="imgCaption">{{ imageDiagramSection?.imageCaption }}</p>
  </div>
</div>
