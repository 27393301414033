import { Component, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { CommonDetails } from 'src/app/shared/viewModels/common-details';
import { environment } from 'src/environments/environment';
import { NewsDetailsInterface } from 'src/app/shared/viewModels/news-details-interface';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss'],
})
export class NewsDetailsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  breadCrumb: any = [];
  title: string | undefined = '';
  brief: string | undefined = '';
  titleNews: string | undefined = '';
  briefNews: SafeHtml | undefined = '';
  img: string | undefined = '';
  paramID: string | null = '';
  data: NewsDetailsInterface | undefined;
  related: NewsDetailsInterface[] | undefined;
// newsDate=new Date()
  image: string = '../../../../assets/images/news-details/AboutUs.svg';

  constructor(
    private getData: GetDataService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this.paramID = this.route.snapshot.paramMap.get('id');
  }

  newsDetails() {
    this.breadCrumb = [
      { path: 'Latest News', routerLink: '/news' },
      {
        path: this.data?.title || 'name',
        routerLink: 'news/details/:id',
      },
    ];
    this.title = this.data?.title;
    this.brief = this.data?.introduction;
    this.titleNews = this.data?.title;
    this.briefNews = this.sanitizer.bypassSecurityTrustHtml(
      this.data?.body || ''
    );
    this.img = this.data?.imageUrl;
  }

  ngOnInit() {
    this.getData
      .getData(`${environment.apiBaseUrl}/api/v1/news/${this.paramID}`)
      .subscribe(
        (data) => {
          this.data = data?.data[0];
          this.related = data?.related;
          this.newsDetails();
          this.hideLoading();
          //Send page title to google analytics
          
          this.sendPageViewEvent(`News - ${data?.data[0]?.title}`);
        },
        (error) => {
          console.log(error);
          this.newsDetails();
        }
      );
  }

  private sendPageViewEvent(pageName: string): void {
    gtag('config', 'G-YGS8HSR5WZ', {
      page_title: pageName,
      page_path: window.location.pathname,
    });
  }

  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
