import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { HomeComponent } from './views/home/home.component';
import { CustomersComponent } from './views/home/customers/customers.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PartnersComponent } from './views/home/partners/partners.component';
import { HeaderComponent } from './views/home/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { TestimonialsComponent } from './views/home/testimonials/testimonials.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { QeemaNumbersComponent } from './views/home/qeema-numbers/qeema-numbers.component';
import { MatIconModule } from '@angular/material/icon';
import { CountUpOnScrollDirective } from './shared/directives/count-up-on-scroll.directive';
import { TopServicesComponent } from './views/home/top-services/top-services.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { InnerBannerComponent } from './shared/components/inner-banner/inner-banner.component';
import { ServicesComponent } from './views/services/services.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TabsComponent } from './shared/components/tabs/tabs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonCardComponent } from './shared/components/common-card/common-card.component';
import { DetailsTechnologyStackComponent } from './shared/components/details-technology-stack/details-technology-stack.component';
import { DetailsAskForComponent } from './shared/components/details-ask-for/details-ask-for.component';
import { DetailsAboutComponent } from './shared/components/details-about/details-about.component';
import { DetailsImageDiagramComponent } from './shared/components/details-image-diagram/details-image-diagram.component';
import { ServicesDetailsComponent } from './views/services-details/services-details.component';
import { DetailsCommonCardsComponent } from './shared/components/details-common-cards/details-common-cards.component';
import { RouterModule } from '@angular/router';
import { DetailsAskProductsComponent } from './shared/components/details-ask-products/details-ask-products.component';
import { DigitalTransformationComponent } from './views/services/digital-transformation/digital-transformation.component';
import { DetailsInNumbersComponent } from './shared/components/details-in-numbers/details-in-numbers.component';
import { ProductsComponent } from './views/products/products.component';
import { ProductsDetailsComponent } from './views/products-details/products-details.component';
import { CommonCardProductsComponent } from './shared/components/common-card-products/common-card-products.component';
import { TopProductsComponent } from './views/home/top-products/top-products.component';
import { CommonTabsComponent } from './shared/components/common-tabs/common-tabs.component';
import { CommonCardTopProductsComponent } from './shared/components/common-card-top-products/common-card-top-products.component';
import { DetailsVideoComponent } from './views/products-details/details-video/details-video.component';
import { DetailsMoreImagesComponent } from './views/products-details/details-more-images/details-more-images.component';
import { DetailsCoreFeaturesComponent } from './shared/components/details-core-features/details-core-features.component';
import { DetailsRelatedComponentComponent } from './shared/components/details-related-component/details-related-component.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { WhoWeAreComponent } from './views/about-us/who-we-are/who-we-are.component';
import { StaticsComponent } from './shared/components/statics/statics.component';
import { QeemaFeaturesComponent } from './shared/components/qeema-features/qeema-features.component';
import { SuccessDriversComponent } from './shared/components/success-drivers/success-drivers.component';
import { AboutUsAskforComponent } from './views/about-us/about-us-askfor/about-us-askfor.component';
import { NewsComponent } from './views/news/news.component';
import { NewsCardComponent } from './shared/components/news-card/news-card.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { NewsDetailsComponent } from './views/news-details/news-details.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NewsroomComponent } from './views/home/newsroom/newsroom.component';
import { SafePipe } from './shared/Pipes/safe.pipe';
import { ToastrModule } from 'ngx-toastr';
import { GetInTouchComponent } from './shared/components/get-in-touch/get-in-touch.component';
import { LoadingPageComponent } from './shared/components/loading-page/loading-page.component';
import { HammerModule } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent,
    WhoWeAreComponent,
    DefaultLayoutComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    DetailsInNumbersComponent,
    CustomersComponent,
    PartnersComponent,
    HeaderComponent,
    TestimonialsComponent,
    QeemaNumbersComponent,
    CountUpOnScrollDirective,
    TopServicesComponent,
    BreadcrumbComponent,
    InnerBannerComponent,
    ServicesComponent,
    TabsComponent,
    CommonCardComponent,
    DetailsTechnologyStackComponent,
    DetailsAskForComponent,
    DetailsAboutComponent,
    DetailsImageDiagramComponent,
    ServicesDetailsComponent,
    DetailsCoreFeaturesComponent,
    DetailsCommonCardsComponent,
    DetailsAskProductsComponent,
    DigitalTransformationComponent,
    DetailsAskProductsComponent,
    ProductsComponent,
    ProductsDetailsComponent,
    CommonCardProductsComponent,
    TopProductsComponent,
    CommonTabsComponent,
    CommonCardTopProductsComponent,
    DetailsVideoComponent,
    DetailsMoreImagesComponent,
    DetailsRelatedComponentComponent,
    ContactUsComponent,
    NewsDetailsComponent,
    AboutUsAskforComponent,
    AboutUsComponent,
    WhoWeAreComponent,
    StaticsComponent,
    QeemaFeaturesComponent,
    SuccessDriversComponent,
    AboutUsAskforComponent,
    NewsComponent,
    NewsCardComponent,
    NewsroomComponent,
    NewsCardComponent,
    SafePipe,
    GetInTouchComponent,
    LoadingPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    NgxPaginationModule,
    AppRoutingModule,
    SlickCarouselModule,
    MatIconModule,
    MatTabsModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatTooltipModule,
    NgxCaptchaModule,
    RouterModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: false,
      timeOut: 5000,
      positionClass: 'toast-top-right',
    }),
    HammerModule,
  ],

  exports: [],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
